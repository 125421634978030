const StakingButtonBorder: React.FC<{ active: boolean }> = ({ active }) => {
	return (
		<svg
			width="287"
			height="48"
			viewBox="0 0 287 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
		</svg>
	);
};

export default StakingButtonBorder;
