import * as web3 from '@solana/web3.js';

export const FIRST_CREATOR_IS_LAST_INDEX = false ;//false; // change to false for mainnet as it's the first index 
                                                // instead of last


export const NETWORK = "mainnet-beta";

export const programId : web3.PublicKey = 

new web3.PublicKey("BFJZi62BC4wse3RkHj6AeurhVspEazACUDC96VFntHtR");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 6; 

export const DC_TOKEN_MINT = "aoUpST1bQEgCqYGbgq1e1rU4wyg2bf8R7UfAJSjbKX7";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "8u51S3z5CX3Ui6r5nq2C3NZ4GV3zWTQdmDwDTWo2AEXF";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "https://stake.goomies.io/api/tokenvault?mint=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "99ZnDknhCLr2j7GBpgCH2xkTUpCMtFaxPVGAx4mkoAhD";

export const STAKE_DATA_API = "https://stake.goomies.io/api/stakeData";


export const SIGN_IN_API = "https://stake.goomies.io/api/signin";


export const SIGN_OUT_API = "https://stake.goomies.io/api/signout";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "BRAiNSRTXfk6Rx95FAfsQVAfDctgqNR6PrYAxsa3sjhv";

export const DC_VALID_FIRST_CREATOR = "";
